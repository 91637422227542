import axios from 'axios'


let loadingNum = 0; //内存中正在请求的数量

function startLoading() {
  if (loadingNum == 0) {
  }
  loadingNum++;
}
function endLoading() {
  loadingNum--
  if (loadingNum <= 0) {
  }
}
// 请求拦截
axios.interceptors.request.use(
  config => {
    if (localStorage.getItem('access') && localStorage.getItem('isStylikaH5Login') ) {
      config.headers.Authorization = 'Bearer ' + localStorage.getItem("access");
    }
    startLoading()
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截
axios.interceptors.response.use(
  response => {
    endLoading()
    if(response.status == 401){
      window.localStorage.clear()
    }
    return response;
  },
  err => {
    console.log(err)
    // 错误提醒
    endLoading()

    if (err && err.response) {
      if(err.response.status == 401){
        window.localStorage.clear()
      }
      // switch (err.response.status) {
      //   case 400: err.message = '请求错误'; break;
      //   case 401: err.message = '登录信息有误，或该用户不存在'; break;
      //   case 403: err.message = '拒绝访问'; break;
      //   case 404: err.message = '请求出错'; break;
      //   case 408: err.message = '请求超时'; break;
      //   case 500: err.message = '服务器错误'; break;
      //   case 501: err.message = '服务未实现'; break;
      //   case 502: err.message = '网络错误'; break;
      //   case 503: err.message = '服务不可用'; break;
      //   case 504: err.message = '网络超时'; break;
      //   case 505: err.message = 'HTTP版本不受支持'; break;
      //   default: err.message = `连接出错(${err.response.status})!`;
      // }
    } else {
      // err.message = '连接服务器失败!'
    }
    // Message.error(err.message);
    return Promise.reject(err);
  }
);

export default {
  get(url, params) {
    return axios.get(url, params)
  },
  post(url, data) {
    return axios.post(url, data)
  },
  delete(url, data) {
    return axios.delete(url, { data })
  },
  put(url, data) {
    return axios.put(url, data)
  },
  patch(url, data) {
    return axios.patch(url, data)
  }
}

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Course',
    component: () => import('@/views/Course.vue')
  },
  {
    path: '/Order',
    name: 'Order',
    component: () => import('@/views/Order.vue'),
    meta: {
      keepAlive: true   //判断是否缓存
    }
  },
  {
    path: '/addressList',
    name: 'addressList',
    component: () => import('@/views/address/addressList.vue')
  },
  {
    path: '/addressAdd',
    name: 'addressAdd',
    component: () => import('@/views/address/addressAdd.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

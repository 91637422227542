import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import iHttp from './net/index';
import Vant from 'vant';
import 'vant/lib/index.css';
import wx from 'weixin-js-sdk'

import tools from "./utils/tools.js"

Vue.use(Vant);

Vue.config.productionTip = false

Vue.prototype.$iHttp = iHttp
Vue.prototype.$tools = tools
Vue.prototype.$wx = wx


new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

// //配置微信浏览器内消息分享设置

//   wx.updateAppMessageShareData({
//     title: "喜藩Stylika", // 分享标题
//     desc: this.course_short.classShort, // 分享描述
//     link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
//     imgUrl: this.photoList[0], // 分享图标
//     success: function() {
//       // 设置成功
//       console.log("SetShareMessageParameters success!");
//     }
//   });
// //配置微信浏览器内朋友圈分享设置

//   wx.updateTimelineShareData({
//     title: this.title, // 分享标题
//     link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
//     imgUrl: this.photoList[0], // 分享图标
//     success: function() {
//       // 设置成功
//       console.log("SetShareTimelineParameters success!");
//     }
//   });
//路由守卫
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // }
  window.scrollTo(0, 0);
  if (from.name === 'Order' && to.name === 'Course') {
    from.meta.keepAlive = false
  }
  if (from.name === 'Course' && to.name === 'Order') {
    setTimeout(()=>{
      to.meta.keepAlive = true
    },1000)
  }
  next()

})